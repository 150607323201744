<template>
  <view-item-setting>
    <template #operation>
      <en-button type="primary" @click="operation.add.click">添加业务人员</en-button>
    </template>

    <flex-box>
      <template #default="{ height }">
        <en-table :data="table.data" :height="height" :loading="table.loading" :method="table.get" pagination :paging="table.paging">
          <en-table-column label="操作" width="200">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayAuditorDto'] }">
              <button-delete :method="table.operation.delete.click" :params="row"> 删除 </button-delete>
            </template>
          </en-table-column>
          <en-table-column label="提醒类型" prop="type.message"></en-table-column>
          <en-table-column label="人员" prop="user.name"> </en-table-column>
          <en-table-column label="手机号" prop="user.cellphone"> </en-table-column>
          <en-table-column label="备注" prop="comment">
            <template #default="{ row }: { row: EnosprayCommonDefinitions['SprayAuditorDto'] }">
              <en-button type="primary" text @click="table.comment.click(row)">{{ row.comment }}</en-button>
            </template>
          </en-table-column>
        </en-table>
      </template>
    </flex-box>
  </view-item-setting>
  <en-drawer v-model="detail.visible">
    <en-form :model="detail.form.data" :loading="detail.form.loading" :ref="setRef('detailForm')">
      <en-form-item label="提醒类型">
        <select-maintain
          v-model="detail.form.data.type"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['ADTTYPE']) }"
          :props="{
            label: 'message',
            value: ''
          }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="人员名称" prop="user">
        <select-maintain
          v-model="detail.form.data.user"
          :ajax="{ action: 'GET /enocloud/common/user', params: (params, value) => (params.payload = { name: value }) }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="手机号" prop="user.cellphone">
        <en-input v-model="detail.form.data.user.cellphone" disabled></en-input>
      </en-form-item>
      <en-form-item label="备注" prop="comment">
        <en-input v-model="detail.form.data.comment" type="textarea" maxlength="200" show-word-limit></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="detail.footer.cancel.click">取消</en-button>
      <button-ajax :method="detail.footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
export default factory({
  config: {
    children: {
      operation: {
        add: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        },
        edit: {
          click() {
            this.detail.form.init()
            this.detail.visible = true
          }
        }
      },

      table: {
        ajax: {
          get: {
            action: 'GET /enospray/common/auditor',
            data: 'array',
            loading: true,
            pagination: true,
            params(params) {
              params.payload = { type: 'SERVICE_DISCOUNT_RATE' }
            }
          },
          delete: {
            action: 'DELETE /enospray/common/auditor/:auditorId',
            loading: true
          }
        },
        children: {
          operation: {
            delete: {
              async click(row: EnosprayCommonDefinitions['SprayAuditorDto']) {
                await this.table.delete({ paths: [row.id] })
                return this.table.get()
              }
            },
            edit: {
              click(row: EnosprayCommonDefinitions['SprayAuditorDto']) {
       
              }
            }
          },
          comment: {
            click(row: EnosprayCommonDefinitions['SprayAuditorDto']) {
              this.detail.form.init()
              this.detail.form.data = Object.assign({}, row)
              this.detail.visible = true
            }
          }
        }
      },
      detail: {
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              user: {
                cellphone: ''
              }
            } as EnosprayCommonDefinitions['SprayAuditorDto'],
            ajax: {
              submit: {
                action: 'POST /enospray/common/auditor',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              },
              update: {
                action: 'PUT /enospray/common/auditor',
                loading: true,
                validate: true,
                params(params) {
                  params.payload = this.detail.form.data
                }
              }
            }
            // rules: { user: [{ required: true, message: '请输入人员姓名' }] }
          },
          footer: {
            cancel: {
              click() {
                this.detail.visible = false
              }
            },
            confirm: {
              async click() {
                await this.detail.form[this.detail.form.data.id ? 'update' : 'submit']()
                return this.table.get().then(() => (this.detail.visible = false))
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.table.get()
  }
})
</script>
